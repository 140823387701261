import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout';

import Gallery from '@browniebroke/gatsby-image-gallery';
//import '@browniebroke/gatsby-image-gallery/dist/style.css';
import SEO from '../components/SEO/SEO';

if (typeof window !== `undefined`) {
  require('bootstrap/dist/css/bootstrap.min.css');
}

export default function OurWork({ data }) {
  const images = [
    data.dreamforceSign,
    data.dreamforceBooth,
    data.diorBottle,
    data.stoneGolem,
    data.dragon,
    data.portal,
    data.legoShip,
    data.gameOfGears,
    data.twoFigures,
    data.fiberglassBull,
    data.giantHead,
    data.crashDummyHead,
    data.djBooths,
    data.bull,
    data.einstein,
    data.stackingToyFoam,
    data.krustyKrab,
    data.bulls,
    data.iceCream,
    data.cutInHalfCar,
  ]
    .filter(img => img)
    .map(node => node.childImageSharp)
    .filter(img => img);
  return (
    <Layout>
      <SEO title="Our Work" />
      <div className="container site-content">
        <Gallery images={images} mdColWidth={33} gutter={'1rem'} />
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query OurWorkQuery {
    dreamforceSign: file(relativePath: { eq: "dreamforce_sign.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    dreamforceBooth: file(relativePath: { eq: "dreamforce-booth.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    diorBottle: file(relativePath: { eq: "dior-bottle.png" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    stoneGolem: file(relativePath: { eq: "stone-golem.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          transformOptions: { cropFocus: CENTER }
        )
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    dragon: file(relativePath: { eq: "dragon.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          transformOptions: { cropFocus: CENTER }
        )
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    portal: file(relativePath: { eq: "portal.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    legoShip: file(relativePath: { eq: "lego-ship.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    gameOfGearsSign: file(relativePath: { eq: "game-of-gears-sign.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    twoFigures: file(relativePath: { eq: "two-figures.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    fiberglassBull: file(relativePath: { eq: "fiberglass-bull.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          transformOptions: { cropFocus: CENTER }
        )
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    gianHead: file(relativePath: { eq: "giant-head.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    crashDummyHead: file(relativePath: { eq: "crash-dummy-head.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    djBooths: file(relativePath: { eq: "dj-booths.png" }) {
      childImageSharp {
        thumb: gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          transformOptions: { cropFocus: CENTER }
        )
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    oversizedBull: file(relativePath: { eq: "oversized-bull.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    einstein: file(relativePath: { eq: "einstein.png" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    stackingToyFoam: file(relativePath: { eq: "stacking-toy-foam.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          transformOptions: { cropFocus: CENTER }
        )
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    krustyKrab: file(relativePath: { eq: "krusty-krab.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    bulls: file(relativePath: { eq: "bulls.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(layout: CONSTRAINED, width: 400, height: 400)
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    iceCream: file(relativePath: { eq: "ice-cream.jpg" }) {
      childImageSharp {
        thumb: gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          transformOptions: { cropFocus: CENTER }
        )
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
    cutInHalfCar: file(relativePath: { eq: "cut-in-half-car.jpeg" }) {
      childImageSharp {
        thumb: gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          transformOptions: { cropFocus: CENTER }
        )
        full: gatsbyImageData(layout: CONSTRAINED, width: 1024)
      }
    }
  }
`;

// images: allFile(filter: { relativeDirectory: { eq: "" } }) {
//   edges {
//     node {
//       childImageSharp {
//         thumb: fluid(maxWidth: 400, maxHeight: 400) {
//           ...GatsbyImageSharpFluid
//           originalName
//         }
//         full: fluid(maxWidth: 1024) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// },
